import { inject } from '@angular/core';
import { runStoreAction, StoreAction, getStoreByName, runEntityStoreAction, EntityStoreAction, applyTransaction, withTransaction, arrayUpdate, arrayRemove, arrayUpsert, arrayAdd } from '@datorama/akita';
import { ReplaySubject, pipe, isObservable, of, combineLatest, Subject, race, lastValueFrom, throwError } from 'rxjs';
import { map, startWith, pairwise, filter, switchMap, tap, takeUntil, distinctUntilChanged, share, finalize } from 'rxjs/operators';
import { fromRef, snapToData, collectionChanges as collectionChanges$1, QueryConstraint, Firestore, doc as doc$1, collection as collection$1, queryEqual, query, collectionGroup, getDoc, getDocs, writeBatch, runTransaction } from '@angular/fire/firestore';
import { Router, UrlTree } from '@angular/router';
import { OAuthProvider, TwitterAuthProvider, GoogleAuthProvider, GithubAuthProvider, FacebookAuthProvider, EmailAuthProvider, Auth, authState, createUserWithEmailAndPassword, signInAnonymously, signInWithEmailAndPassword, signInWithPopup, signInWithCustomToken, getAdditionalUserInfo } from '@angular/fire/auth';
import { httpsCallableData } from '@angular/fire/functions';

// Helper to retrieve the id and path of a document in the collection
function getIdAndPath(options, collectionPath) {
  let path = '';
  let id = '';
  if (options['id']) {
    if (!collectionPath) {
      throw new Error('You should provide the colletion path with the id');
    }
    id = options['id'];
    path = `${collectionPath}/${id}`;
  } else if (options['path']) {
    path = options['path'];
    const part = path.split('/');
    if (part.length % 2 !== 0) {
      throw new Error(`Path ${path} doesn't look like a Firestore's document path`);
    }
    id = part[part.length - 1];
  } else {
    throw new Error(`You should provide either an "id" OR a "path".`);
  }
  return {
    id,
    path
  };
}

/** Set the loading parameter of a specific store */
function setLoading(storeName, loading) {
  runStoreAction(storeName, StoreAction.Update, update => update({
    loading
  }));
}
/** Reset the store to an empty array */
function resetStore(storeName) {
  getStoreByName(storeName).reset();
}
/** Set a entity as active */
function setActive(storeName, active) {
  runStoreAction(storeName, StoreAction.Update, update => update({
    active
  }));
}
/** Create or update one or several entities in the store */
function upsertStoreEntity(storeName, data, id) {
  runEntityStoreAction(storeName, EntityStoreAction.UpsertEntities, upsert => upsert(id, data));
}
/** Remove one or several entities in the store */
function removeStoreEntity(storeName, entityIds) {
  runEntityStoreAction(storeName, EntityStoreAction.RemoveEntities, remove => remove(entityIds));
}
/** Update one or several entities in the store */
function updateStoreEntity(removeAndAdd, storeName, entityIds, data) {
  if (removeAndAdd) {
    applyTransaction(() => {
      removeStoreEntity(storeName, entityIds);
      upsertStoreEntity(storeName, data, entityIds);
    });
  } else {
    runEntityStoreAction(storeName, EntityStoreAction.UpdateEntities, update => update(entityIds, data));
  }
}
/** Sync a specific store with actions from Firestore */
async function syncStoreFromDocAction(storeName, changes, idKey = 'id', removeAndAdd, mergeRef, formatFromFirestore) {
  setLoading(storeName, false);
  if (changes.length === 0) {
    return;
  }
  for (const change of changes) {
    const id = change.doc.id;
    const entity = change.doc.data();
    if (mergeRef) {
      await mergeReference(entity);
    }
    const formattedEntity = formatFromFirestore(entity);
    switch (change.type) {
      case 'added':
        {
          upsertStoreEntity(storeName, {
            [idKey]: id,
            ...formattedEntity
          }, id);
          break;
        }
      case 'removed':
        {
          removeStoreEntity(storeName, id);
          break;
        }
      case 'modified':
        {
          updateStoreEntity(removeAndAdd, storeName, id, formattedEntity);
          break;
        }
    }
  }
}
/** Sync a specific store with actions from Firestore */
async function syncStoreFromDocSnapshot(storeName, snapshot, idKey = 'id', mergeRef, formatFromFirestore) {
  setLoading(storeName, false);
  const id = snapshot.id;
  const entity = snapshot.data();
  if (mergeRef) {
    await mergeReference(entity);
  }
  const formattedEntity = formatFromFirestore(entity);
  if (!snapshot.exists()) {
    removeStoreEntity(storeName, id);
  } else {
    upsertStoreEntity(storeName, {
      [idKey]: id,
      ...formattedEntity
    }, id);
  }
}
async function mergeReference(entity) {
  for (const key in entity) {
    if (typeof entity[key] === 'object') {
      if (entity[key]?.get) {
        const ref = await entity[key].get();
        entity[key] = ref.data();
        await mergeReference(entity[key]);
      } else {
        await mergeReference(entity[key]);
      }
    }
  }
  return entity;
}

/**
 * Get the store name of a store to be synced
 */
function getStoreName(store, storeOptions = {}) {
  if (!store && !storeOptions.storeName) {
    throw new Error('You should either provide a store name or inject a store instance in constructor');
  }
  return storeOptions.storeName || store.storeName;
}

/** Get the params from a path */
function getPathParams(path) {
  return path.split('/').filter(segment => segment.charAt(0) === ':').map(segment => segment.substr(1));
}
/**
 * Transform a path based on the params
 * @param path The path with params starting with "/:"
 * @param params A map of id params
 * @example pathWithParams('movies/:movieId/stakeholder/:shId', { movieId, shId })
 */
function pathWithParams(path, params) {
  return path.split('/').map(segment => {
    if (segment.charAt(0) === ':') {
      const key = segment.substr(1);
      if (!params[key]) {
        throw new Error(`Required parameter ${key} from ${path} doesn't exist in params ${JSON.stringify(params)}`);
      }
      return params[key];
    } else {
      return segment;
    }
  }).join('/');
}

/**
 * Same as Object.getOwnPropertyDescriptor, but recursively checks prototype chain excluding passed currentClass
 * @param instance Instance to check on
 * @param property Property name to check
 * @param currentClass Checks prototype chain until this class
 * @example getPropertyDescriptor(this, 'path', CollectionService)
 */
function getPropertyDescriptor(instance, property, currentClass = Object) {
  const prototype = Object.getPrototypeOf(instance);
  if (!prototype || !(prototype instanceof currentClass)) return;
  return Object.getOwnPropertyDescriptor(prototype, property) || getPropertyDescriptor(prototype, property, currentClass);
}
/**
 * Check prototype chain for a specific getter function, excluding parent class
 * @param instance Instance of the class to check on
 * @param parentClass Parent class of the instance
 * @param property Property name to check
 * @example hasChildGetter(this, CollectionService, 'path')
 */
function hasChildGetter(instance, parentClass, property) {
  const descriptor = getPropertyDescriptor(instance, property, parentClass);
  return descriptor && descriptor.get && true;
}

/**
 * Replay the data and share it across source.
 * It will unsubscribe after a delay when there is no more subscriber
 * This is useful if you unsubscribe from a page & resubscribe on the other
 * @note code based on shareReplay of rxjs v6.6.7: https://github.com/ReactiveX/rxjs/blob/6.6.7/src/internal/operators/shareReplay.ts
 * @param delay Delay in ms to wait before unsubscribing
 */
function shareWithDelay(delay = 100) {
  let subject;
  let subscription;
  let refCount = 0;
  let hasError = false;
  let isComplete = false;
  let lastValue;
  function operation(source) {
    refCount++;
    let innerSub;
    if (!subject || hasError) {
      hasError = false;
      subject = new ReplaySubject(1, Infinity);
      if (lastValue) subject.next(lastValue);
      innerSub = subject.subscribe(this);
      subscription = source.subscribe({
        next(value) {
          subject?.next(value);
          lastValue = value;
        },
        error(err) {
          hasError = true;
          subject?.error(err);
        },
        complete() {
          isComplete = true;
          subscription = undefined;
          subject?.complete();
        }
      });
      // Here we need to check to see if the source synchronously completed. Although
      // we're setting `subscription = undefined` in the completion handler, if the source
      // is synchronous, that will happen *before* subscription is set by the return of
      // the `subscribe` call.
      if (isComplete) {
        subscription = undefined;
      }
    } else {
      innerSub = subject.subscribe(this);
    }
    this.add(() => {
      refCount--;
      innerSub?.unsubscribe();
      innerSub = undefined;
      // await some ms before unsubscribing
      setTimeout(() => {
        if (subscription && !isComplete && refCount === 0) {
          subscription.unsubscribe();
          subscription = undefined;
          subject = undefined;
        }
      }, delay);
    });
  }
  return source => source.lift(operation);
}
function docValueChanges(ref, options) {
  return fromRef(ref, options).pipe(map(snap => snapToData(snap)));
}
function collectionValueChanges(query, options) {
  return fromRef(query, options).pipe(map(changes => changes.docs), map(snapshots => snapshots.map(snap => snapToData(snap))));
}
/**
 * Create an operator that allows you to compare the current emission with
 * the prior, even on first emission (where prior is undefined).
 */
const windowwise = () => pipe(startWith(undefined), pairwise());
/**
 * Create an operator that filters out empty changes. We provide the
 * ability to filter on events, which means all changes can be filtered out.
 * This creates an empty array and would be incorrect to emit.
 */
const filterEmptyUnlessFirst = () => pipe(windowwise(), filter(([prior, current]) => current.length > 0 || prior === undefined), map(([, current]) => current));
function collectionChanges(query, options) {
  return !options.includeMetadataChanges ? fromRef(query, options).pipe(map(snapshot => snapshot.docChanges()), filterEmptyUnlessFirst()) : collectionChanges$1(query, options);
}
function isArray(entityOrArray) {
  return Array.isArray(entityOrArray);
}
function isQueryConstraints(data) {
  return Array.isArray(data) && data.every(item => item instanceof QueryConstraint);
}
function isArrayOfIds(data) {
  return Array.isArray(data) && data.every(item => typeof item === 'string');
}
function isEmptyArray(data) {
  return Array.isArray(data) && !data.length;
}
/** check is an Atomic write is a transaction */
function isTransaction(write) {
  return write && !!write['get'];
}
class CollectionService {
  constructor(store, collectionPath, db) {
    this.store = store;
    this.collectionPath = collectionPath;
    // keep memory of the current ids to listen to (for syncManyDocs)
    this.idsToListen = {};
    this.memoPath = {};
    this.memoQuery = new Map();
    /** If true, it will multicast observables from the same ID */
    this.useMemorization = false;
    this.includeMetadataChanges = false;
    if (!hasChildGetter(this, CollectionService, 'path') && !this.constructor['path'] && !this.collectionPath) {
      throw new Error('You should provide a path to the collection');
    }
    try {
      this.db = db || inject(Firestore);
    } catch (err) {
      throw new Error('CollectionService requires AngularFirestore.');
    }
  }
  createId() {
    return doc$1(collection$1(this.db, '_')).id;
  }
  fromMemo(key, cb) {
    if (!this.useMemorization) return cb();
    if (typeof key === 'string') {
      if (!this.memoPath[key]) {
        this.memoPath[key] = cb().pipe(shareWithDelay());
      }
      return this.memoPath[key];
    } else {
      for (const queryRef of this.memoQuery.keys()) {
        if (typeof queryRef !== 'string' && queryEqual(queryRef, key)) {
          return this.memoQuery.get(queryRef);
        }
      }
      this.memoQuery.set(key, cb().pipe(shareWithDelay()));
      return this.memoQuery.get(key);
    }
  }
  getPath(options) {
    return options && options.params ? pathWithParams(this.path, options.params) : this.currentPath;
  }
  get idKey() {
    return this.constructor['idKey'] || this.store ? this.store.idKey : 'id';
  }
  /** The path to the collection in Firestore */
  get path() {
    return this.constructor['path'] || this.collectionPath;
  }
  /** A snapshot of the path */
  get currentPath() {
    if (isObservable(this.path)) {
      throw new Error('Cannot get a snapshot of the path if it is an Observable');
    }
    return this.path;
  }
  get resetOnUpdate() {
    return this.constructor['resetOnUpdate'] || false;
  }
  get mergeRef() {
    return this.constructor['mergeReference'] || false;
  }
  /**
   * The Angular Fire collection
   * @notice If path is an observable, it becomes an observable.
   */
  get collection() {
    return collection$1(this.db, this.currentPath);
  }
  /**
   * Function triggered when adding/updating data to firestore
   * @note should be overridden
   */
  formatToFirestore(entity) {
    return entity;
  }
  /**
   * Function triggered when getting data from firestore
   * @note should be overridden
   */
  formatFromFirestore(entity) {
    return entity;
  }
  syncCollection(pathOrQuery = this.currentPath, queryOrOptions, syncOptions = {
    loading: true
  }) {
    let path;
    let queryConstraints = [];
    // check type of pathOrQuery
    if (isQueryConstraints(pathOrQuery)) {
      queryConstraints = pathOrQuery;
      path = this.getPath(queryOrOptions);
    } else if (typeof pathOrQuery === 'object') {
      syncOptions = pathOrQuery;
      path = this.getPath(syncOptions);
    } else if (typeof pathOrQuery === 'string') {
      path = pathOrQuery;
    } else {
      path = this.getPath(syncOptions);
    }
    // check type of queryOrOptions
    if (isQueryConstraints(queryOrOptions)) {
      queryConstraints = queryOrOptions;
    } else if (typeof queryOrOptions === 'object') {
      syncOptions = queryOrOptions;
    }
    const storeName = getStoreName(this.store, syncOptions);
    // reset has to happen before setLoading, otherwise it will also reset the loading state
    if (syncOptions.reset) {
      resetStore(storeName);
    }
    if (syncOptions.loading) {
      setLoading(storeName, true);
    }
    const collectionRef = collection$1(this.db, path);
    const syncQuery = query(collectionRef, ...queryConstraints);
    // Start Listening
    return collectionChanges(syncQuery, {
      includeMetadataChanges: this.includeMetadataChanges
    }).pipe(withTransaction(actions => syncStoreFromDocAction(storeName, actions, this.idKey, this.resetOnUpdate, this.mergeRef, entity => this.formatFromFirestore(entity))));
  }
  syncCollectionGroup(idOrQuery = this.currentPath, queryOrOption, syncOptions = {
    loading: true
  }) {
    let path;
    let queryConstraints = [];
    if (typeof idOrQuery === 'string') {
      path = idOrQuery;
    } else if (isQueryConstraints(idOrQuery)) {
      path = this.currentPath;
      queryConstraints = idOrQuery;
    } else if (typeof idOrQuery === 'object') {
      path = this.currentPath;
      syncOptions = idOrQuery;
    } else {
      throw new Error('1ier parameter if either a string, query constraints or a StoreOption');
    }
    if (isQueryConstraints(queryOrOption)) {
      queryConstraints = queryOrOption;
    } else if (typeof queryOrOption === 'object') {
      syncOptions = queryOrOption;
    }
    const storeName = getStoreName(this.store, syncOptions);
    // reset has to happen before setLoading, otherwise it will also reset the loading state
    if (syncOptions.reset) {
      resetStore(storeName);
    }
    if (syncOptions.loading) {
      setLoading(storeName, true);
    }
    const collectionId = path.split('/').pop();
    const collectionGroupRef = collectionGroup(this.db, collectionId);
    const syncGroupQuery = query(collectionGroupRef, ...queryConstraints);
    return collectionChanges(syncGroupQuery, {
      includeMetadataChanges: this.includeMetadataChanges
    }).pipe(withTransaction(actions => syncStoreFromDocAction(storeName, actions, this.idKey, this.resetOnUpdate, this.mergeRef, entity => this.formatFromFirestore(entity))));
  }
  syncManyDocs(ids$, syncOptions = {
    loading: true
  }) {
    if (!isObservable(ids$)) {
      ids$ = of(ids$);
    }
    const storeName = getStoreName(this.store, syncOptions);
    // reset has to happen before setLoading, otherwise it will also reset the loading state
    if (syncOptions.reset) {
      resetStore(storeName);
    }
    if (syncOptions.loading) {
      setLoading(storeName, true);
    }
    return ids$.pipe(switchMap(ids => {
      // Remove previous ids that have changed
      const previousIds = this.idsToListen[storeName];
      if (previousIds) {
        const idsToRemove = previousIds.filter(id => !ids.includes(id));
        removeStoreEntity(storeName, idsToRemove);
      }
      this.idsToListen[storeName] = ids;
      // Return empty array if no ids are provided
      if (!ids.length) {
        return of([]);
      }
      // Sync all docs
      const syncs = ids.map(id => {
        const path = `${this.getPath(syncOptions)}/${id}`;
        const docRef = doc$1(this.db, path);
        return fromRef(docRef, {
          includeMetadataChanges: this.includeMetadataChanges
        });
      });
      return combineLatest(syncs).pipe(tap(snapshots => snapshots.map(snapshot => syncStoreFromDocSnapshot(storeName, snapshot, this.idKey, this.mergeRef, entity => this.formatFromFirestore(entity)))));
    }));
  }
  /**
   * Stay in sync with one document
   * @param docOptions An object with EITHER `id` OR `path`.
   * @note We need to use id and path because there is no way to differentiate them.
   * @param syncOptions Options on the store to sync to
   */
  syncDoc(docOptions, syncOptions = {
    loading: false
  }) {
    const storeName = getStoreName(this.store, syncOptions);
    const collectionPath = this.getPath(syncOptions);
    const {
      id,
      path
    } = getIdAndPath(docOptions, collectionPath);
    // reset has to happen before setLoading, otherwise it will also reset the loading state
    if (syncOptions.reset) {
      resetStore(storeName);
    }
    if (syncOptions.loading) {
      setLoading(storeName, true);
    }
    return docValueChanges(doc$1(this.db, path), {
      includeMetadataChanges: this.includeMetadataChanges
    }).pipe(map(entity => {
      if (!entity) {
        setLoading(storeName, false);
        // note: We don't removeEntity as it would result in weird behavior
        return undefined;
      }
      const data = this.formatFromFirestore({
        [this.idKey]: id,
        ...entity
      });
      upsertStoreEntity(storeName, data, id);
      setLoading(storeName, false);
      return data;
    }));
  }
  syncActive(options, syncOptions) {
    const storeName = getStoreName(this.store, syncOptions);
    if (Array.isArray(options)) {
      return this.syncManyDocs(options, syncOptions).pipe(tap(() => setActive(storeName, options)));
    } else {
      return this.syncDoc(options, syncOptions).pipe(tap(entity => entity ? setActive(storeName, entity[this.idKey]) : null));
    }
  }
  getRef(idOrQuery, options = {}) {
    const path = this.getPath(options);
    // If path targets a collection ( odd number of segments after the split )
    if (typeof idOrQuery === 'string') {
      return doc$1(this.db, `${path}/${idOrQuery}`);
    }
    if (Array.isArray(idOrQuery)) {
      return idOrQuery.map(id => doc$1(this.db, `${path}/${id}`));
    } else if (typeof idOrQuery === 'object') {
      const subpath = this.getPath(idOrQuery);
      return collection$1(this.db, subpath);
    } else {
      return collection$1(this.db, path, idOrQuery);
    }
  }
  async getValue(idOrQuery, options = {}) {
    const path = this.getPath(options);
    // If path targets a collection ( odd number of segments after the split )
    if (typeof idOrQuery === 'string') {
      const snapshot = await getDoc(doc$1(this.db, `${path}/${idOrQuery}`));
      return snapshot.exists() ? this.formatFromFirestore({
        ...snapshot.data(),
        [this.idKey]: snapshot.id
      }) : null;
    }
    let docs;
    if (isArrayOfIds(idOrQuery)) {
      docs = await Promise.all(idOrQuery.map(id => {
        return getDoc(doc$1(this.db, `${path}/${id}`));
      }));
    } else if (isQueryConstraints(idOrQuery)) {
      const collectionQuery = query(collection$1(this.db, path), ...idOrQuery);
      const snaphot = await getDocs(collectionQuery);
      docs = snaphot.docs;
    } else {
      const subpath = this.getPath(idOrQuery);
      const snapshot = await getDocs(collection$1(this.db, subpath));
      docs = snapshot.docs;
    }
    return docs.filter(docSnapshot => docSnapshot.exists()).map(docSnapshot => ({
      ...docSnapshot.data(),
      [this.idKey]: docSnapshot.id
    })).map(entity => this.formatFromFirestore(entity));
  }
  valueChanges(idOrQuery, options = {}) {
    const path = this.getPath(options);
    // If path targets a collection ( odd number of segments after the split )
    if (typeof idOrQuery === 'string') {
      const key = `${path}/${idOrQuery}`;
      const docRef = doc$1(this.db, key);
      const valueChangeQuery = () => docValueChanges(docRef, {
        includeMetadataChanges: this.includeMetadataChanges
      });
      return this.fromMemo(key, valueChangeQuery).pipe(map(entity => this.formatFromFirestore(entity)));
    }
    let entities$;
    if (isEmptyArray(idOrQuery)) {
      return of([]);
    }
    if (isArrayOfIds(idOrQuery)) {
      const queries = idOrQuery.map(id => {
        const key = `${path}/${id}`;
        const docRef = doc$1(this.db, key);
        const valueChangeQuery = () => docValueChanges(docRef, {
          includeMetadataChanges: this.includeMetadataChanges
        });
        return this.fromMemo(key, valueChangeQuery);
      });
      entities$ = combineLatest(queries);
    } else if (isQueryConstraints(idOrQuery)) {
      const collectionQuery = collection$1(this.db, path);
      const cb = () => collectionValueChanges(query(collectionQuery, ...idOrQuery), {
        includeMetadataChanges: this.includeMetadataChanges
      });
      entities$ = this.fromMemo(collectionQuery, cb);
    } else {
      const subpath = this.getPath(idOrQuery);
      const collectionQuery = collection$1(this.db, subpath);
      const cb = () => collectionValueChanges(collectionQuery, {
        includeMetadataChanges: this.includeMetadataChanges
      });
      entities$ = this.fromMemo(collectionQuery, cb);
    }
    return entities$.pipe(map(entities => entities.map(entity => this.formatFromFirestore(entity))));
  }
  ///////////
  // WRITE //
  ///////////
  /**
   * Create a batch object.
   * @note alias for `writeBatch(firestore)`
   */
  batch() {
    return writeBatch(this.db);
  }
  /**
   * Run a transaction
   * @note alias for `runTransaction(firestore, cb)`
   */
  runTransaction(cb) {
    return runTransaction(this.db, tx => cb(tx));
  }
  /**
   * Create or update entities
   * @param entities One or many entities
   * @param options options to write the document on firestore
   */
  async upsert(entities, options = {}) {
    const doesExist = async entity => {
      const ref = this.getRef(entity[this.idKey]);
      const {
        exists
      } = await (isTransaction(options.write) ? options.write.get(ref) : getDoc(ref));
      return exists();
    };
    if (!isArray(entities)) {
      return (await doesExist(entities)) ? this.update(entities, options).then(() => entities[this.idKey]) : this.add(entities, options);
    }
    const toAdd = [];
    const toUpdate = [];
    for (const entity of entities) {
      (await doesExist(entity)) ? toUpdate.push(entity) : toAdd.push(entity);
    }
    return Promise.all([this.add(toAdd, options), this.update(toUpdate, options).then(() => toUpdate.map(entity => entity[this.idKey]))]).then(([added, updated]) => added.concat(updated));
  }
  /**
   * Add a document or a list of document to Firestore
   * @param oneOrMoreEntities A document or a list of document
   * @param options options to write the document on firestore
   */
  async add(oneOrMoreEntities, options = {}) {
    const entities = Array.isArray(oneOrMoreEntities) ? oneOrMoreEntities : [oneOrMoreEntities];
    const {
      write = this.batch(),
      ctx
    } = options;
    const path = this.getPath(options);
    const operations = entities.map(async entity => {
      const id = entity[this.idKey] || this.createId();
      const data = this.formatToFirestore({
        ...entity,
        [this.idKey]: id
      });
      const ref = doc$1(this.db, `${path}/${id}`);
      write.set(ref, data);
      if (this.onCreate) {
        await this.onCreate(data, {
          write,
          ctx
        });
      }
      return id;
    });
    const ids = await Promise.all(operations);
    // If there is no atomic write provided
    if (!options.write) {
      await write.commit();
    }
    return Array.isArray(oneOrMoreEntities) ? ids : ids[0];
  }
  /**
   * Remove one or several document from Firestore
   * @param id A unique or list of id representing the document
   * @param options options to write the document on firestore
   */
  async remove(id, options = {}) {
    const {
      write = this.batch(),
      ctx
    } = options;
    const path = this.getPath(options);
    const ids = Array.isArray(id) ? id : [id];
    const operations = ids.map(async docId => {
      const ref = doc$1(this.db, `${path}/${docId}`);
      write.delete(ref);
      if (this.onDelete) {
        await this.onDelete(docId, {
          write,
          ctx
        });
      }
    });
    await Promise.all(operations);
    // If there is no atomic write provided
    if (!options.write) {
      return write.commit();
    }
  }
  /** Remove all document of the collection */
  async removeAll(options = {}) {
    const path = this.getPath(options);
    const collectionRef = collection$1(this.db, path);
    const snapshot = await getDocs(collectionRef);
    const ids = snapshot.docs.map(entity => entity.id);
    return this.remove(ids, options);
  }
  async update(idsOrEntity, stateFnOrWrite, options = {}) {
    let ids = [];
    let stateFunction;
    let getData;
    const isEntity = value => {
      return typeof value === 'object' && value[this.idKey];
    };
    const isEntityArray = values => {
      return Array.isArray(values) && values.every(value => isEntity(value));
    };
    if (isEntity(idsOrEntity)) {
      ids = [idsOrEntity[this.idKey]];
      getData = () => idsOrEntity;
      options = stateFnOrWrite || {};
    } else if (isEntityArray(idsOrEntity)) {
      const entityMap = new Map(idsOrEntity.map(entity => [entity[this.idKey], entity]));
      ids = Array.from(entityMap.keys());
      getData = docId => entityMap.get(docId);
      options = stateFnOrWrite || {};
    } else if (typeof stateFnOrWrite === 'function') {
      ids = Array.isArray(idsOrEntity) ? idsOrEntity : [idsOrEntity];
      stateFunction = stateFnOrWrite;
    } else if (typeof stateFnOrWrite === 'object') {
      ids = Array.isArray(idsOrEntity) ? idsOrEntity : [idsOrEntity];
      getData = () => stateFnOrWrite;
    } else {
      throw new Error('Passed parameters match none of the function signatures.');
    }
    const {
      ctx
    } = options;
    const path = this.getPath(options);
    if (!Array.isArray(ids) || !ids.length) {
      return;
    }
    // If update depends on the entity, use transaction
    if (stateFunction) {
      return this.runTransaction(async tx => {
        const operations = ids.map(async id => {
          const ref = doc$1(this.db, `${path}/${id}`);
          const snapshot = await tx.get(ref);
          const entity = Object.freeze({
            ...snapshot.data(),
            [this.idKey]: id
          });
          const data = await stateFunction(entity, tx);
          tx.update(ref, this.formatToFirestore(data));
          if (this.onUpdate) {
            await this.onUpdate(data, {
              write: tx,
              ctx
            });
          }
          return tx;
        });
        return Promise.all(operations);
      });
    } else {
      const {
        write = this.batch()
      } = options;
      const operations = ids.map(async docId => {
        const entity = Object.freeze(getData(docId));
        if (!docId) {
          throw new Error(`Document should have an unique id to be updated, but none was found in ${entity}`);
        }
        const ref = doc$1(this.db, `${path}/${docId}`);
        write.update(ref, this.formatToFirestore(entity));
        if (this.onUpdate) {
          await this.onUpdate(entity, {
            write,
            ctx
          });
        }
      });
      await Promise.all(operations);
      // If there is no atomic write provided
      if (!options.write) {
        return write.commit();
      }
      return;
    }
  }
}

/** Set the configuration for the collection service */
function CollectionConfig(options = {}) {
  return constructor => {
    Object.keys(options).forEach(key => constructor[key] = options[key]);
  };
}
const initialAuthState = {
  uid: null,
  emailVerified: undefined,
  profile: null,
  loading: false
};
const authProviders = ['github', 'google', 'microsoft', 'facebook', 'twitter', 'email', 'apple'];
/** Verify if provider is part of the list of Authentication provider provided by Firebase Auth */
function isFireAuthProvider(provider) {
  return typeof provider === 'string' && authProviders.includes(provider);
}
/**
 * Get the custom claims of a user. If no key is provided, return the whole claims object
 * @param user The user object returned by Firebase Auth
 * @param roles Keys of the custom claims inside the claim objet
 */
async function getCustomClaims(user, roles) {
  const {
    claims
  } = await user.getIdTokenResult();
  if (!roles) {
    return claims;
  }
  const keys = Array.isArray(roles) ? roles : [roles];
  return Object.keys(claims).filter(key => keys.includes(key)).reduce((acc, key) => {
    acc[key] = claims[key];
    return acc;
  }, {});
}
/**
 * Get the Authentication Provider based on its name
 * @param provider string literal representing the name of the provider
 */
function getAuthProvider(provider) {
  switch (provider) {
    case 'email':
      return new EmailAuthProvider();
    case 'facebook':
      return new FacebookAuthProvider();
    case 'github':
      return new GithubAuthProvider();
    case 'google':
      return new GoogleAuthProvider();
    case 'microsoft':
      return new OAuthProvider('microsoft.com');
    case 'twitter':
      return new TwitterAuthProvider();
    case 'apple':
      return new OAuthProvider('apple.com');
  }
}
class FireAuthService {
  constructor(store, db, auth) {
    this.store = store;
    this.collectionPath = 'users';
    this.includeMetadataChanges = false;
    this.db = db || inject(Firestore);
    this.auth = auth || inject(Auth);
    this.collection = collection$1(this.db, this.path);
  }
  /**
   * Select the profile in the Firestore
   * @note can be override to point to a different place
   */
  selectProfile(user) {
    const ref = doc$1(this.collection, user.uid);
    return docValueChanges(ref, {
      includeMetadataChanges: this.includeMetadataChanges
    });
  }
  /**
   * Select the roles for this user. Can be in custom claims or in a Firestore collection
   * @param user The user given by FireAuth
   * @see getCustomClaims to get the custom claims out of the user
   * @note Can be overwritten
   */
  selectRoles(user) {
    return of(null);
  }
  /**
   * Function triggered when getting data from firestore
   * @note should be overwritten
   */
  formatFromFirestore(user) {
    return user;
  }
  /**
   * Function triggered when adding/updating data to firestore
   * @note should be overwritten
   */
  formatToFirestore(user) {
    return user;
  }
  /**
   * Function triggered when transforming a user into a profile
   * @param user The user object from FireAuth
   * @param ctx The context given on signup
   * @note Should be override
   */
  createProfile(user, ctx) {
    return {
      photoURL: user.photoURL,
      displayName: user.displayName
    };
  }
  /**
   * The current sign-in user (or null)
   * @returns a Promise in v6.*.* & a snapshot in v5.*.*
   */
  get user() {
    return this.auth.currentUser;
  }
  get idKey() {
    return this.constructor['idKey'] || 'id';
  }
  /** The path to the profile in firestore */
  get path() {
    return this.constructor['path'] || this.collectionPath;
  }
  /** Start listening on User */
  sync() {
    return authState(this.auth).pipe(switchMap(user => user ? combineLatest([of(user), this.selectProfile(user), this.selectRoles(user)]) : of([undefined, undefined, undefined])), tap(([user = {}, userProfile, roles]) => {
      const profile = this.formatFromFirestore(userProfile);
      const {
        uid,
        emailVerified
      } = user;
      this.store.update({
        uid,
        emailVerified,
        profile,
        roles
      });
    }), map(([user, userProfile, roles]) => user ? [user, this.formatFromFirestore(userProfile), roles] : null));
  }
  /**
   * @description Delete user from authentication service and database
   * WARNING This is security sensitive operation
   */
  async delete(options = {}) {
    const user = await this.user;
    if (!user) {
      throw new Error('No user connected');
    }
    const {
      write = writeBatch(this.db),
      ctx
    } = options;
    const ref = doc$1(this.collection, user.uid);
    write.delete(ref);
    if (this.onDelete) {
      await this.onDelete({
        write,
        ctx
      });
    }
    if (!options.write) {
      await write.commit();
    }
    return user.delete();
  }
  /** Update the current profile of the authenticated user */
  async update(profile, options = {}) {
    const user = await this.user;
    if (!user.uid) {
      throw new Error('No user connected.');
    }
    const ref = doc$1(this.collection, user.uid);
    if (typeof profile === 'function') {
      return runTransaction(this.db, async tx => {
        const snapshot = await tx.get(ref);
        const userDoc = Object.freeze({
          ...snapshot.data(),
          [this.idKey]: snapshot.id
        });
        const data = profile(this.formatToFirestore(userDoc), tx);
        tx.update(ref, data);
        if (this.onUpdate) {
          await this.onUpdate(data, {
            write: tx,
            ctx: options.ctx
          });
        }
        return tx;
      });
    } else if (typeof profile === 'object') {
      const {
        write = writeBatch(this.db),
        ctx
      } = options;
      write.update(ref, this.formatToFirestore(profile));
      if (this.onUpdate) {
        await this.onUpdate(profile, {
          write,
          ctx
        });
      }
      // If there is no atomic write provided
      if (!options.write) {
        return write.commit();
      }
    }
  }
  /** Create a user based on email and password */
  async signup(email, password, options = {}) {
    const cred = await createUserWithEmailAndPassword(this.auth, email, password);
    const {
      write = writeBatch(this.db),
      ctx
    } = options;
    if (this.onSignup) {
      await this.onSignup(cred, {
        write,
        ctx
      });
    }
    const profile = await this.createProfile(cred.user, ctx);
    const ref = doc$1(this.collection, cred.user.uid);
    write.set(ref, this.formatToFirestore(profile));
    if (this.onCreate) {
      await this.onCreate(profile, {
        write,
        ctx
      });
    }
    if (!options.write) {
      await write.commit();
    }
    return cred;
  }
  async signin(provider, passwordOrOptions) {
    this.store.setLoading(true);
    let profile;
    try {
      let cred;
      const write = writeBatch(this.db);
      if (!provider) {
        cred = await signInAnonymously(this.auth);
      } else if (passwordOrOptions && typeof provider === 'string' && typeof passwordOrOptions === 'string') {
        cred = await signInWithEmailAndPassword(this.auth, provider, passwordOrOptions);
      } else if (typeof provider === 'object') {
        cred = await signInWithPopup(this.auth, provider);
      } else if (isFireAuthProvider(provider)) {
        const authProvider = getAuthProvider(provider);
        cred = await signInWithPopup(this.auth, authProvider);
      } else {
        cred = await signInWithCustomToken(this.auth, provider);
      }
      if (getAdditionalUserInfo(cred).isNewUser) {
        if (this.onSignup) {
          await this.onSignup(cred, {});
        }
        profile = await this.createProfile(cred.user);
        this.store.update({
          profile
        });
        const ref = doc$1(this.collection, cred.user.uid);
        write.set(ref, this.formatToFirestore(profile));
        if (this.onCreate) {
          if (typeof passwordOrOptions === 'object') {
            await this.onCreate(profile, {
              write,
              ctx: passwordOrOptions.ctx
            });
          } else {
            await this.onCreate(profile, {
              write,
              ctx: {}
            });
          }
        }
        await write.commit();
      } else {
        try {
          const userRef = doc$1(this.collection, cred.user.uid);
          const document = await getDoc(userRef);
          const {
            uid,
            emailVerified
          } = cred.user;
          if (document.exists()) {
            profile = this.formatFromFirestore(document.data());
          } else {
            profile = await this.createProfile(cred.user);
            write.set(userRef, this.formatToFirestore(profile));
            write.commit();
          }
          this.store.update({
            profile,
            uid,
            emailVerified
          });
        } catch (error) {
          console.error(error);
        }
      }
      if (this.onSignin) {
        await this.onSignin(cred);
      }
      this.store.setLoading(false);
      return cred;
    } catch (err) {
      this.store.setLoading(false);
      if (err.code === 'auth/operation-not-allowed') {
        console.warn('You tried to connect with a disabled auth provider. Enable it in Firebase console');
      }
      throw err;
    }
  }
  /** Signs out the current user and clear the store */
  async signOut() {
    await this.auth.signOut();
    this.store.update(initialAuthState);
    if (this.onSignout) {
      await this.onSignout();
    }
  }
}
function CollectionGuardConfig(data) {
  return constructor => {
    Object.keys(data).forEach(key => constructor[key] = data[key]);
  };
}
class CollectionGuard {
  constructor(service) {
    this.service = service;
    try {
      this.router = inject(Router);
    } catch (err) {
      throw new Error('CollectionGuard requires RouterModule to be imported');
    }
  }
  // Can be override by the extended class
  /** Should the guard wait for connection to Firestore to complete */
  get awaitSync() {
    return this.constructor['awaitSync'] || false;
  }
  // Can be override by the extended class
  /** Query constraints for sync */
  get queryConstraints() {
    return this.constructor['queryConstraints'];
  }
  // Can be override by the extended class
  /** The route to redirect to if you sync failed */
  get redirect() {
    return this.constructor['redirect'];
  }
  // Can be override by the extended class
  /** The method to subscribe to while route is active */
  sync(next) {
    const {
      queryConstraints = this.queryConstraints
    } = next.data;
    if (this.service instanceof FireAuthService) {
      return this.service.sync();
    } else if (this.service instanceof CollectionService) {
      return this.service.syncCollection(queryConstraints);
    }
  }
  canActivate(next, state) {
    const {
      redirect = this.redirect,
      awaitSync = this.awaitSync
    } = next.data;
    return new Promise(resolve => {
      if (awaitSync) {
        const unsubscribe = new Subject();
        this.subscription = this.sync(next).pipe(takeUntil(unsubscribe)).subscribe({
          next: result => {
            if (result instanceof UrlTree) {
              return resolve(result);
            }
            switch (typeof result) {
              case 'string':
                unsubscribe.next();
                unsubscribe.complete();
                return resolve(this.router.parseUrl(result));
              case 'boolean':
                return resolve(result);
              default:
                return resolve(true);
            }
          },
          error: err => {
            resolve(this.router.parseUrl(redirect || ''));
            throw new Error(err);
          }
        });
      } else {
        this.subscription = this.sync(next).subscribe();
        resolve(true);
      }
    });
  }
  canDeactivate() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    return true;
  }
}
function canWrite(write, roles) {
  return roles.some(role => role === write || role === 'write');
}
function canRead(read, roles) {
  return roles.some(role => role === read || role === 'write');
}
function hasRole(role, roles) {
  switch (role) {
    case 'write':
      return roles.includes('write');
    case 'create':
      return canWrite('create', roles);
    case 'delete':
      return canWrite('delete', roles);
    case 'update':
      return canWrite('update', roles);
    case 'read':
      return roles.includes('read');
    case 'get':
      return canRead('get', roles);
    case 'list':
      return canRead('list', roles);
    default:
      return false;
  }
}
function shouldCancel({
  validate,
  cancel
}) {
  return race([validate.pipe(map(_ => false)), cancel.pipe(map(_ => true))]);
}
async function waitForCancel({
  startWith,
  endWith,
  shouldValidate,
  shouldCancel
}) {
  startWith();
  const cancelled = await lastValueFrom(race([shouldValidate.pipe(map(_ => false)), shouldCancel.pipe(map(_ => true))]));
  endWith(cancelled);
}

/**
 * @description Custom RxJs operator
 * @param redirectTo Route path to redirecto if collection is empty
 */
function redirectIfEmpty(redirectTo) {
  return map(actions => actions.length === 0 ? redirectTo : true);
}
function syncWithRouter(routerQuery) {
  if (!this['store'].resettable) {
    throw new Error(`Store ${this['store'].storeName} is required to be resettable for syncWithRouter to work.`);
  }
  const pathParams = getPathParams(this.path);
  return routerQuery.selectParams().pipe(
  // Don't trigger change if params needed (and only them) haven't changed
  distinctUntilChanged((old, next) => {
    const paramsHaveChanged = !!pathParams.find(param => old[param] !== next[param]);
    // reset store on every parameter change
    if (paramsHaveChanged) {
      this['store'].reset();
    }
    return !paramsHaveChanged;
  }),
  // Need to filter because changes in params comes before canDeactivate
  filter(params => pathParams.every(param => !!params[param])), switchMap(params => this.syncCollection({
    params: {
      ...params
    }
  })), share());
}
const queryKeys = ['path', 'queryConstraints'];
function getSubQueryKeys(query) {
  const keys = Object.keys(query);
  return keys.filter(key => !queryKeys.includes(key));
}
function hasSubQueries(query) {
  return getSubQueryKeys(query).length > 0;
}
function getSubQuery(query, parent) {
  if (typeof query !== 'function') {
    return query;
  }
  return query(parent);
}
function isDocPath(path) {
  return path.split('/').length % 2 === 0;
}
/** Transform a path into a collection Query */
function collection(path, queryConstraints) {
  return {
    path,
    queryConstraints
  };
}
/** Transform a path into a doc query */
function doc(path) {
  return {
    path
  };
}
/** Check if a value is a query */
function isQuery(query) {
  if (typeof query === 'object' && query !== null) {
    return !!query['path'];
  }
  return false;
}

/**
 * Sync the collection
 * @param this Uses this function with bind on a Collection Service
 * @param query The query to trigger
 */
function syncQuery(query$1) {
  // If single query
  if (typeof query$1 === 'string') {
    return isDocPath(query$1) ? this.syncDoc({
      path: query$1
    }) : this.syncCollection(query$1);
  }
  if (Array.isArray(query$1)) {
    return combineLatest(query$1.map(oneQuery => syncQuery.call(this, oneQuery)));
  }
  if (!isQuery(query$1)) {
    throw new Error('Query should be either a path, a Query object or an array of Queries');
  }
  ////////////////
  // SUBQUERIES //
  ////////////////
  /** Listen on Child actions */
  const fromChildAction = (changes, child) => {
    const idKey = 'id'; // TODO: Improve how to
    const {
      parentId,
      key
    } = child;
    for (const change of changes) {
      const id = change.doc.id;
      const data = change.doc.data();
      switch (change.type) {
        case 'added':
          {
            this['store'].update(parentId, entity => ({
              [key]: arrayUpsert(entity[key] || [], id, data, idKey)
            }));
            break;
          }
        case 'removed':
          {
            this['store'].update(parentId, entity => ({
              [key]: arrayRemove(entity[key], id, idKey)
            }));
            break;
          }
        case 'modified':
          {
            this['store'].update(parentId, entity => ({
              [key]: arrayUpdate(entity[key], id, data, idKey)
            }));
          }
      }
    }
  };
  /**
   * Stay in sync a subquery of the collection
   * @param subQuery The sub query based on the entity
   * @param child An object that describe the parent
   */
  const syncSubQuery = (subQuery, child) => {
    const {
      parentId,
      key
    } = child;
    // If it's a static value
    // TODO : Check if subQuery is a string ???
    if (!isQuery(subQuery)) {
      const update = this['store'].update(parentId, {
        [key]: subQuery
      });
      return of(update);
    }
    if (Array.isArray(subQuery)) {
      const syncQueries = subQuery.map(oneQuery => {
        if (isQuery(subQuery)) {
          const id = getIdAndPath({
            path: subQuery.path
          });
          return docValueChanges(doc$1(this.db, subQuery.path), {
            includeMetadataChanges: this.includeMetadataChanges
          }).pipe(tap(childDoc => {
            this['store'].update(parentId, entity => ({
              [key]: arrayAdd(entity[key], id, childDoc)
            }));
          }));
        }
        return syncSubQuery(oneQuery, child);
      });
      return combineLatest(syncQueries);
    }
    if (typeof subQuery !== 'object') {
      throw new Error('Query should be either a path, a Query object or an array of Queries');
    }
    // Sync subquery
    if (isDocPath(subQuery.path)) {
      return docValueChanges(doc$1(this.db, subQuery.path), {
        includeMetadataChanges: this.includeMetadataChanges
      }).pipe(tap(children => this['store'].update(parentId, {
        [key]: children
      })));
    } else {
      return collectionChanges(query(collection$1(this.db, subQuery.path), ...(subQuery.queryConstraints || [])), {
        includeMetadataChanges: this.includeMetadataChanges
      }).pipe(withTransaction(changes => fromChildAction(changes, child)));
    }
  };
  /**
   * Get in sync with all the subqueries
   * @param subQueries A map of all the subqueries
   * @param parent The parent entity to attach the children to
   */
  const syncAllSubQueries = (subQueries, parent) => {
    const obs = Object.keys(subQueries).filter(key => key !== 'path' && key !== 'queryConstraints').map(key => {
      const queryLike = getSubQuery(subQueries[key], parent);
      const child = {
        key,
        parentId: parent[this.idKey]
      };
      return syncSubQuery(queryLike, child);
    });
    return combineLatest(obs);
  };
  ////////////////
  // MAIN QUERY //
  ////////////////
  /** Listen on action with child queries */
  const fromActionWithChild = (changes, mainQuery, subscriptions) => {
    for (const change of changes) {
      const id = change.doc.id;
      const data = change.doc.data();
      switch (change.type) {
        case 'added':
          {
            const entity = this.formatFromFirestore({
              [this.idKey]: id,
              ...data
            });
            this['store'].upsert(id, entity);
            subscriptions[id] = syncAllSubQueries(mainQuery, entity).subscribe();
            break;
          }
        case 'removed':
          {
            this['store'].remove(id);
            subscriptions[id].unsubscribe();
            delete subscriptions[id];
            break;
          }
        case 'modified':
          {
            const entity = this.formatFromFirestore(data);
            this['store'].update(id, entity);
          }
      }
    }
  };
  const {
    path,
    queryConstraints = []
  } = query$1;
  if (isDocPath(path)) {
    const {
      id
    } = getIdAndPath({
      path
    });
    let subscription;
    return docValueChanges(doc$1(this.db, path), {
      includeMetadataChanges: this.includeMetadataChanges
    }).pipe(tap(entity => {
      this['store'].upsert(id, this.formatFromFirestore({
        id,
        ...entity
      }));
      if (!subscription) {
        // Subscribe only the first time
        subscription = syncAllSubQueries(query$1, entity).subscribe();
      }
    }),
    // Stop subscription
    finalize(() => subscription.unsubscribe()));
  } else {
    const subscriptions = {};
    return collectionChanges(query(collection$1(this.db, path), ...queryConstraints), {
      includeMetadataChanges: this.includeMetadataChanges
    }).pipe(withTransaction(changes => fromActionWithChild(changes, query$1, subscriptions)),
    // Stop all subscriptions
    finalize(() => Object.keys(subscriptions).forEach(id => {
      subscriptions[id].unsubscribe();
      delete subscriptions[id];
    })));
  }
}
function awaitSyncQuery(query) {
  return queryChanges.call(this, query).pipe(tap(entities => {
    Array.isArray(entities) ? this['store'].upsertMany(entities) : this['store'].upsert(entities[this.idKey], entities);
  }));
}
/**
 * Listen on the changes of the documents in the query
 * @param query A query object to listen to
 */
function queryChanges(query) {
  return awaitQuery.call(this, query).pipe(map(entities => {
    return Array.isArray(entities) ? entities.map(e => this.formatFromFirestore(e)) : this.formatFromFirestore(entities);
  }));
}
function awaitQuery(query$1) {
  const options = {
    includeMetadataChanges: this.includeMetadataChanges
  };
  // If single query
  if (typeof query$1 === 'string') {
    return isDocPath(query$1) ? docValueChanges(doc$1(this.db, query$1), options) : collectionValueChanges(collection$1(this.db, query$1), options);
  }
  if (Array.isArray(query$1)) {
    return !!query$1.length ? combineLatest(query$1.map(oneQuery => awaitSyncQuery.call(this, oneQuery))) : of(query$1);
  }
  if (!isQuery(query$1)) {
    return of(query$1);
  }
  /**
   * Get the entity of one subquery
   * @param subQueryFn The subquery function or value
   * @param entity The parent entity
   */
  const syncSubQuery = (subQueryFn, entity) => {
    if (!subQueryFn) {
      return throwError(`Query failed`);
    }
    if (typeof subQueryFn !== 'function') {
      return of(subQueryFn);
    }
    return awaitQuery.call(this, subQueryFn(entity));
  };
  /**
   * Get all the Entities of all subqueries
   * @param parentQuery The parent Query
   * @param entity The parent Entity
   */
  const getAllSubQueries = (parentQuery, entity) => {
    if (!entity) {
      // Nothing found at path
      return of(undefined);
    }
    // There is no subqueries return the entity
    if (!hasSubQueries(parentQuery)) {
      return of(entity);
    }
    // Get all subquery keys
    const subQueryKeys = getSubQueryKeys(query$1);
    // For each key get the subquery
    const subQueries$ = subQueryKeys.map(key => {
      return syncSubQuery(parentQuery[key], entity).pipe(tap(subentity => entity[key] = subentity));
    });
    return !!subQueries$.length ? combineLatest(subQueries$).pipe(map(() => entity)) : of(entity);
  };
  // IF DOCUMENT
  const {
    path,
    queryConstraints = []
  } = query$1;
  if (isDocPath(path)) {
    const {
      id
    } = getIdAndPath({
      path
    });
    const entityRef = doc$1(this.db, path);
    return docValueChanges(entityRef, {
      includeMetadataChanges: this.includeMetadataChanges
    }).pipe(switchMap(entity => getAllSubQueries(query$1, entity)), map(entity => entity ? {
      id,
      ...entity
    } : undefined));
  }
  // IF COLLECTION
  const collectionRef = collection$1(this.db, path);
  return collectionValueChanges(query(collectionRef, ...queryConstraints), {
    includeMetadataChanges: this.includeMetadataChanges
  }).pipe(switchMap(entities => {
    const entities$ = entities.map(entity => getAllSubQueries(query$1, entity));
    return entities$.length ? combineLatest(entities$) : of([]);
  }));
}

/**
 * @description calls cloud function
 * @param functions you want to make callable
 * @param name of the cloud function
 * @param params you want to set
 */
async function callFunction(functions, name, param) {
  const callable = httpsCallableData(functions, name);
  return lastValueFrom(callable(param));
}

/*
 * Public API Surface of akita-ng-fire
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CollectionConfig, CollectionGuard, CollectionGuardConfig, CollectionService, FireAuthService, authProviders, awaitQuery, awaitSyncQuery, callFunction, canRead, canWrite, collection, doc, getAuthProvider, getCustomClaims, getIdAndPath, getPathParams, getSubQuery, getSubQueryKeys, hasRole, hasSubQueries, initialAuthState, isDocPath, isFireAuthProvider, isQuery, isTransaction, pathWithParams, queryChanges, queryKeys, redirectIfEmpty, removeStoreEntity, resetStore, setActive, setLoading, shouldCancel, syncQuery, syncStoreFromDocAction, syncStoreFromDocSnapshot, syncWithRouter, updateStoreEntity, upsertStoreEntity, waitForCancel };
